import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Header from "./Header";
import "./styles/Coder.css";
import projectList from "./constants/HackerProjects.json";
import certList from "./constants/HackerCerts.json";

export default class Hacker extends Component {
  render() {
    const hackerProjects = projectList.map((item, key) => (
      <VerticalTimelineElement
        key={key}
        className="vertical-timeline-element--work"
        date={item.Date}
        iconStyle={{ background: "#FF4081", color: "black" }}
        icon={
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="skull"
            className="svg-inline--fa fa-skull fa-w-16"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path
              fill="currentColor"
              d="M256 0C114.6 0 0 100.3 0 224c0 70.1 36.9 132.6 94.5 173.7 9.6 6.9 15.2 18.1 13.5 29.9l-9.4 66.2c-1.4 9.6 6 18.2 15.7 18.2H192v-56c0-4.4 3.6-8 8-8h16c4.4 0 8 3.6 8 8v56h64v-56c0-4.4 3.6-8 8-8h16c4.4 0 8 3.6 8 8v56h77.7c9.7 0 17.1-8.6 15.7-18.2l-9.4-66.2c-1.7-11.7 3.8-23 13.5-29.9C475.1 356.6 512 294.1 512 224 512 100.3 397.4 0 256 0zm-96 320c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64zm192 0c-35.3 0-64-28.7-64-64s28.7-64 64-64 64 28.7 64 64-28.7 64-64 64z"
            ></path>
          </svg>
        }
      >
        <h3 className="vertical-timeline-element-title">{item.Machine}</h3>
        <h4 className="vertical-timeline-element-subtitle">CTF Challenge</h4>
        <p>{item.Description}</p>
      </VerticalTimelineElement>
    ));
    const hackerCerts = certList.map((item, key) => (
      <VerticalTimelineElement
        contentStyle={{ color: "green" }}
        key={key}
        className="vertical-timeline-element--work"
        date={item.Date}
        iconStyle={{ background: "lightblue", color: "black" }}
        icon={
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="award"
            className="svg-inline--fa fa-award fa-w-12"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
          >
            <path
              fill="currentColor"
              d="M97.12 362.63c-8.69-8.69-4.16-6.24-25.12-11.85-9.51-2.55-17.87-7.45-25.43-13.32L1.2 448.7c-4.39 10.77 3.81 22.47 15.43 22.03l52.69-2.01L105.56 507c8 8.44 22.04 5.81 26.43-4.96l52.05-127.62c-10.84 6.04-22.87 9.58-35.31 9.58-19.5 0-37.82-7.59-51.61-21.37zM382.8 448.7l-45.37-111.24c-7.56 5.88-15.92 10.77-25.43 13.32-21.07 5.64-16.45 3.18-25.12 11.85-13.79 13.78-32.12 21.37-51.62 21.37-12.44 0-24.47-3.55-35.31-9.58L252 502.04c4.39 10.77 18.44 13.4 26.43 4.96l36.25-38.28 52.69 2.01c11.62.44 19.82-11.27 15.43-22.03zM263 340c15.28-15.55 17.03-14.21 38.79-20.14 13.89-3.79 24.75-14.84 28.47-28.98 7.48-28.4 5.54-24.97 25.95-45.75 10.17-10.35 14.14-25.44 10.42-39.58-7.47-28.38-7.48-24.42 0-52.83 3.72-14.14-.25-29.23-10.42-39.58-20.41-20.78-18.47-17.36-25.95-45.75-3.72-14.14-14.58-25.19-28.47-28.98-27.88-7.61-24.52-5.62-44.95-26.41-10.17-10.35-25-14.4-38.89-10.61-27.87 7.6-23.98 7.61-51.9 0-13.89-3.79-28.72.25-38.89 10.61-20.41 20.78-17.05 18.8-44.94 26.41-13.89 3.79-24.75 14.84-28.47 28.98-7.47 28.39-5.54 24.97-25.95 45.75-10.17 10.35-14.15 25.44-10.42 39.58 7.47 28.36 7.48 24.4 0 52.82-3.72 14.14.25 29.23 10.42 39.59 20.41 20.78 18.47 17.35 25.95 45.75 3.72 14.14 14.58 25.19 28.47 28.98C104.6 325.96 106.27 325 121 340c13.23 13.47 33.84 15.88 49.74 5.82a39.676 39.676 0 0 1 42.53 0c15.89 10.06 36.5 7.65 49.73-5.82zM97.66 175.96c0-53.03 42.24-96.02 94.34-96.02s94.34 42.99 94.34 96.02-42.24 96.02-94.34 96.02-94.34-42.99-94.34-96.02z"
            ></path>
          </svg>
        }
      >
        <h3 className="vertical-timeline-element-title">{item.Name}</h3>
        <h4 className="vertical-timeline-element-subtitle">{item.Institute}</h4>
      </VerticalTimelineElement>
    ));
    return (
      <div>
        <Header text={"I am a security analyst"} mode={"hacker"} />
        <VerticalTimeline>
          {hackerProjects}
          {hackerCerts}
        </VerticalTimeline>
      </div>
    );
  }
}
