import React from "react";
import "./App.css";
import Landing from "./Landing";
import Landing1 from "./Landing1";

function App() {
  return (
    <div className="App">
      <Landing1 />
    </div>
  );
}

export default App;
